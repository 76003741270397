import Vue from 'vue';
import VueRouter from 'vue-router';
import GlobalData from '@/data/GlobalData';
import JsBridge from '@/lib/JsBridge';
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

Vue.use(VueRouter);

const routes = [
  {
    // 首頁
    path: '/', 
    redirect: {
      name: 'Home',
    },
    component: reslove=>require(["@/views/template/ZEN/Home.vue"],reslove)
  },
  {
    // 首頁
    path: '/Home', 
    name: 'Home',
    component: reslove=>require(["@/views/template/ZEN/Home.vue"],reslove)
  },
  {
    // 我的
    path: '/Mine', 
    name: 'Mine',
    component: reslove=>require(["@/views/template/ZEN/Mine.vue"],reslove)
  },

  {
    // 聯絡客服
    path: '/ContactService', 
    name: 'ContactService',
    component: reslove=>require(["@/views/template/ZEN/ContactService.vue"],reslove)
  },
   
  {
    // 選科目
    path: '/RecommendFriend', 
    name: 'RecommendFriend',
    component: reslove=>require(["@/views/template/ZEN/RecommendFriend.vue"],reslove)
    
  },
  {
    // 大家都在學
    path: '/EverybodyLearn', 
    name: 'EverybodyLearn',
    component: reslove=>require(["@/views/template/ZEN/EverybodyLearn.vue"],reslove)
    
  },
  {
    // 大家都在學
    path: '/EverybodyLearnFilter', 
    name: 'EverybodyLearnFilter',
    component: reslove=>require(["@/views/template/ZEN/EverybodyLearnFilter.vue"],reslove)
    
  },
  {
    // 發問紀錄
    path: '/QuestionRecordList', 
    name: 'QuestionRecordList',
    component: reslove=>require(["@/views/template/ZEN/QuestionRecordList.vue"],reslove)
  },

  {
    // 特價升級VIP會員
    path: '/SpecialUpgradeVip', 
    name: 'SpecialUpgradeVip',
    component: reslove=>require(["@/views/template/ZEN/SpecialUpgradeVip.vue"],reslove)
  },
  {
    // 升級VIP會員
    path: '/UpgradeVip', 
    name: 'UpgradeVip',
    component: reslove=>require(["@/views/template/ZEN/UpgradeVip.vue"],reslove)
  },
  {
    // 延伸學習清單
    path: '/ExtendList', 
    name: 'ExtendList',
    component: reslove=>require(["@/views/template/ZEN/ExtendList.vue"],reslove)
  },
  {
    // 會員資料
    path: '/MemberProfile', 
    name: 'MemberProfile',
    component: reslove=>require(["@/views/template/ZEN/MemberProfile.vue"],reslove)
  },
  {
    // 設定
    path: '/Setting', 
    name: 'Setting',
    component: reslove=>require(["@/views/template/ZEN/Setting.vue"],reslove)
    
  },
  {
    // 任務清單
    path: '/TaskList', 
    name: 'TaskList',
    component: reslove=>require(["@/views/template/ZEN/TaskList.vue"],reslove)
  },
  {
    // Edm
    path: '/EdmUpgradeVip', 
    name: 'EdmUpgradeVip',
    component: reslove=>require(["@/views/template/ZEN/EdmUpgradeVip.vue"],reslove)
  },
  {
    //2-4-7全部釋義-文字例句(句型的學習更多)
    path: '/SentenceAllExplanation', 
    name: 'SentenceAllExplanation',
    component: reslove=>require(["@/views/template/ZEN/SentenceAllExplanation.vue"],reslove)
  },
  {
    //學習紀錄
    path: '/LearningRecord', 
    name: 'LearningRecord',
    component: reslove=>require(["@/views/template/ZEN/LearningRecord.vue"],reslove)
  },
  {
    //訊息中心
    path: '/MessageList', 
    name: 'MessageList',
    component: reslove=>require(["@/views/template/ZEN/MessageList.vue"],reslove)
  },
  {
    //我的收藏
    path: '/Collect', 
    name: 'Collect',
    component: reslove=>require(["@/views/template/ZEN/Collect.vue"],reslove)
  },
  {
    //考題收藏
    path: '/CollectExamGroup', 
    name: 'CollectExamGroup',
    component: reslove=>require(["@/views/template/ZEN/CollectExamGroup.vue"],reslove)
  },
  {
    // 等級測驗目錄
    path: '/LevelExamIntro', 
    name: 'LevelExamIntro',
    component: reslove=>require(["@/views/template/ZEN/LevelExamIntro.vue"],reslove)
  },
  {
    // 我的收藏-文章收藏
    path: '/CollectLessonEssay', 
    name: 'CollectLessonEssay',
    component: reslove=>require(["@/views/template/ZEN/CollectLessonEssay.vue"],reslove)
  },
  {
    // 等級測驗倒數
    path: '/LevelExamIntroReciprocal', 
    name: 'LevelExamIntroReciprocal',
    component: reslove=>require(["@/views/template/ZEN/LevelExamIntroReciprocal.vue"],reslove)
  },
  {
    // 自訂等級內容
    path: '/CustomLevelDescription', 
    name: 'CustomLevelDescription',
    component: reslove=>require(["@/views/template/ZEN/CustomLevelDescription.vue"],reslove)
  },
  {
    //作答結果
    path: '/ListeningExamComplete', 
    name: 'ListeningExamComplete',
    component: reslove=>require(["@/views/template/ZEN/ListeningExamComplete.vue"],reslove)
  },
  {
    // 自訂等級-學習天數及興趣
    path: '/CustomLevelSetting', 
    name: 'CustomLevelSetting',
    component: reslove=>require(["@/views/template/ZEN/CustomLevelSetting.vue"],reslove)
  },
  {
    // 等級測驗-推薦等級(結果頁)
    path: '/LevelExamComplete', 
    name: 'LevelExamComplete',
    component: reslove=>require(["@/views/template/ZEN/LevelExamComplete.vue"],reslove)
  },
  {
    // 等級測驗-作答
    path: '/LevelExamStart', 
    name: 'LevelExamStart',
    component: reslove=>require(["@/views/template/ZEN/LevelExamStart.vue"],reslove)
  },
  {
    //我的課程
    path: '/MyCoursesCustom', 
    name: 'MyCoursesCustom',
    component: reslove=>require(["@/views/template/ZEN/MyCoursesCustom.vue"],reslove)
  },
  {
    // 4-1-12歷史測驗紀錄
    path: '/LevelExamHistory', 
    name: 'LevelExamHistory',
    component: reslove=>require(["@/views/template/ZEN/LevelExamHistory.vue"],reslove)
  },
  {
    //隱私權政策
    path: '/PrivacyPolicy', 
    name: 'PrivacyPolicy',
    component: reslove=>require(["@/views/template/ZEN/PrivacyPolicy.vue"],reslove)
  },  
  {
    //隱私權政策
    path: '/UserTerms', 
    name: 'UserTerms',
    component: reslove=>require(["@/views/template/ZEN/UserTerms.vue"],reslove)
  },  
  {
    //我的課程详情
    path: '/MyCoursesDetail', 
    name: 'MyCoursesDetail',
    component: reslove=>require(["@/views/template/ZEN/MyCoursesDetail.vue"],reslove)
  },
  {
    //訊息中心內頁
    path: '/MessageContent', 
    name: 'MessageContent',
    component: reslove=>require(["@/views/template/ZEN/MessageContent.vue"],reslove)
  },
  {
    // 3-0-3深度學習
    path: '/DeepLearnList', 
    name: 'DeepLearnList',
    component: reslove=>require(["@/views/template/ZEN/DeepLearnList.vue"],reslove)
  },
  {
    // 3-0-1深度學習
    path: '/DeepLearn', 
    name: 'DeepLearn',
    component: reslove=>require(["@/views/template/ZEN/DeepLearn.vue"],reslove)
  },
  {
    // 4-0-4
    path: '/CustomLevelSelect', 
    name: 'CustomLevelSelect',
    component: reslove=>require(["@/views/template/ZEN/CustomLevelSelect.vue"],reslove)
  },
  
  {
    //訂購紀錄
    path: '/OrderRecord',
    name: 'OrderRecord',
    component: reslove=>require(["@/views/template/ZEN/OrderRecord.vue"],reslove)
  },

  {
    //1-6-1聽力測驗
    path: '/ListeningExam',
    name: 'ListeningExam',
    component: reslove=>require(["@/views/template/ZEN/ListeningExam.vue"],reslove)
  },
  {
    // 我的收藏-筆記收藏
    path: '/CollectLessonSentence', 
    name: 'CollectLessonSentence',
    component: reslove=>require(["@/views/template/ZEN/CollectLessonSentence.vue"],reslove)
  },
  {
    //介紹頁面
    path: '/Intro',
    name: 'Intro',
    component: reslove=>require(["@/views/template/ZEN/Intro.vue"],reslove)
  },
  {
    //常見問題
    path: '/CommonQuestion',
    name: 'CommonQuestion',
    component: reslove=>require(["@/views/template/ZEN/CommonQuestion.vue"],reslove)
  },
  {
    //二次發問
    path: '/QuestionRecordDetail',
    name: 'QuestionRecordDetail',
    component: reslove=>require(["@/views/template/ZEN/QuestionRecordDetail.vue"],reslove)
  },             
  {
    //2-3-3單字複習
    path: '/WordReexercise',
    name: 'WordReexercise',
    component: reslove=>require(["@/views/template/ZEN/WordReexercise.vue"],reslove)
  },
  {
    //聯絡客服
    path: '/ContactServiceDetail',
    name: 'ContactServiceDetail',
    component: reslove=>require(["@/views/template/ZEN/ContactServiceDetail.vue"],reslove)
  },
  {
    //聯絡客服
    path: '/CompleteAllTask',
    name: 'CompleteAllTask',
    component: reslove=>require(["@/views/template/ZEN/CompleteAllTask.vue"],reslove)
  },
  {
    // 每日學習
    path: '/DailyLearn',
    name: 'DailyLearn',
    component: reslove=>require(["@/views/template/ZEN/DailyLearn.vue"],reslove)
  },
  {
    // 聽力測驗 - 測驗
    path: '/ListeningExamStart',
    name: 'ListeningExamStart',
    component: reslove=>require(["@/views/template/ZEN/ListeningExamStart.vue"],reslove)
  },
  {
    // 2-2-1單字預習
    path: '/WordPreview',
    name: 'WordPreview',
    component: reslove=>require(["@/views/template/ZEN/WordPreview.vue"],reslove)
  },
  {
    // 2-2-1單字預習
    path: '/Quiz',
    name: 'Quiz',
    component: reslove=>require(["@/views/template/ZEN/Quiz.vue"],reslove)
  },
  {
    // 設定頁->版本說明
    path: '/Instructions',
    name: 'Instructions',
    component: reslove=>require(["@/views/template/ZEN/Instructions.vue"],reslove)
  },
  {
    path: '/Empty',
    name: 'Empty',
    component: reslove=>require(["@/views/template/ZEN/Empty.vue"],reslove)
  },
  {
    // 我的收藏-影音收藏
    path: '/CollectLessonVideo', 
    name: 'CollectLessonVideo',
    component: reslove=>require(["@/views/template/ZEN/CollectLessonVideo.vue"],reslove)
  },
  {
    // 我的收藏-我的單字本
    path: '/CollectLessonWord', 
    name: 'CollectLessonWord',
    component: reslove=>require(["@/views/template/ZEN/CollectLessonWord.vue"],reslove)
  },
  {
    // 首頁-延伸新知
    path: '/ExtendDetail', 
    name: 'ExtendDetail',
    component: reslove=>require(["@/views/template/ZEN/ExtendDetail.vue"],reslove)
  },
  {
    // 所見即所得
    path: '/CustomEdit', 
    name: 'CustomEdit',
    component: reslove=>require(["@/views/template/ZEN/CustomEdit.vue"],reslove)
  },
  {
    // 單元測試
    path: '/UnitTest', 
    name: 'UnitTest',
    component: reslove=>require(["@/views/template/ZEN/UnitTest.vue"],reslove)
  },

  
  

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { 
  var logCallback = function (json){};
  window["logCallback"] = logCallback;  
  //JsBridge.callHandler('log', `name => ${to.name}`, logCallback);
  //JsBridge.callHandler('log', `params => ${JSON.stringify(to.params)}`, logCallback);
  console.log(`name ==> ${to.name}`);
  var checkTaskFirst = function (){
    //JsBridge.callHandler('log', `111111111111`, logCallback);
    if(to.name == "TaskList" && to.params.lsid){
      //JsBridge.callHandler('log', `222222222222222`, logCallback);
      var checkTaskFirstCallback = function (data){
        //JsBridge.callHandler('log', `55555555555`, logCallback);
        //JsBridge.callHandler('log', `data => ${data}`, logCallback);
        if(data != ""){
          //JsBridge.callHandler('log', `333333333333333`, logCallback);
          try{
            var json = JSON.parse(data);
            var isComplete = false;
            Object.keys(json).forEach(function(key){
              var lsid = key.replace('lsid_', '');
              if(lsid == `${to.params.lsid}` && json[key] == "Y"){
                //JsBridge.callHandler('log', `44444444444444`, logCallback);
                isComplete = true;
              }
            });
            if(isComplete){
              //JsBridge.callHandler('log', `555555555555555`, logCallback);
              router.push(
              {
                  name : 'CompleteAllTask',
                  params: to.params,
              });
            }else{
              next();
            }
          }
          catch(err) {
            //JsBridge.callHandler('log', `catch ==============`, logCallback);
            try{
              var json = JSON.parse(decodeURIComponent(atob(data)));
              var isComplete = false;
              Object.keys(json).forEach(function(key){
                var lsid = key.replace('lsid_', '');
                if(lsid == `${to.params.lsid}` && json[key] == "Y"){
                  //JsBridge.callHandler('log', `44444444444444`, logCallback);
                  isComplete = true;
                }
              });
              if(isComplete){
                //JsBridge.callHandler('log', `555555555555555`, logCallback);
                router.push(
                {
                    name : 'CompleteAllTask',
                    params: to.params,
                });
              }else{
                next();
              }
            }
            catch(err) {
              next();
            }
          }
        }else{
          next();
        }
      };
      window["checkTaskFirstCallback"] = checkTaskFirstCallback; 
      JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "isTaskCompleteFirst"}), checkTaskFirstCallback);
    }else{
      next();
    }
  } 
  var getNetworkCallback = function (status){
    if(status == 'Y'){
      GlobalData.isOffline = false;   
      var getUserDataCallback = function (json){
        if(json && json != ""){
          json = decodeURIComponent(atob(json));
          var data = JSON.parse(json);
          Vue.prototype.$GlobalData.user = data;
          if(data.token && data.token != null && data.token != undefined){
            Vue.prototype.$GlobalData.token = data.token;
          }
          if(data.rt && data.rt.user && data.rt.user.length > 0){
            Vue.prototype.$GlobalData.uid = data.rt.user[0].uid;
            if(GlobalData.check_login_status == "Y"){
              JsBridge.callHandler('log', `11111111111111`, logCallback);
              oAC.getAllRecord(oAC.getApiParam({
                apiName: 'login_check'
              }), {uid:data.rt.user[0].uid}).then(
              res => 
                  {
                    JsBridge.callHandler('log', `22222222222222222`, logCallback);
                    if(res.user_device_token != null && res.user_device_token != undefined && res.user_device_token.length > 0){
                      JsBridge.callHandler('log', `33333333333333333`, logCallback);
                      //JsBridge.callHandler('log', `4444444444`, logCallback);
                      //JsBridge.callHandler('log', `res.user_device_token[0].token => ${JSON.stringify(res)}`, logCallback);
                      var token = res.user_device_token[0].token;
                      //JsBridge.callHandler('log', `4444444444 token=> ${token}`, logCallback);
                      //JsBridge.callHandler('log', `555555555 GlobalData.token=> ${GlobalData.token}`, logCallback);
                      if(token != "" && token != GlobalData.token){
                        var dateTime = Date.now();
                        var timestamp = Math.floor(dateTime / 1000);
                        var modifyt = res.user_device_token[0].modifyt;
                        var modifytDateTime = new Date(Date.parse(modifyt.replace(/-/g, '/')));
                        var modifytTimestamp = Math.floor(modifytDateTime / 1000);
                        console.log(`dateTime => ${dateTime}`);
                        JsBridge.callHandler('log', `dateTime => ${dateTime}`, logCallback);
                        console.log(`timestamp => ${timestamp}`);
                        JsBridge.callHandler('log', `timestamp => ${timestamp}`, logCallback);
                        console.log(`modifyt => ${modifyt}`);
                        JsBridge.callHandler('log', `modifyt => ${modifyt}`, logCallback);
                        console.log(`modifytDateTime => ${modifytDateTime}`);
                        JsBridge.callHandler('log', `modifytDateTime => ${modifytDateTime}`, logCallback);
                        console.log(`modifytTimestamp => ${modifytTimestamp}`);
                        JsBridge.callHandler('log', `modifytTimestamp => ${modifytTimestamp}`, logCallback);
                        console.log(`timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`);
                        JsBridge.callHandler('log', `timestamp - modifytTimestamp => ${timestamp - modifytTimestamp}`, logCallback);
                        JsBridge.callHandler('log', `GlobalData.check_login_time ==> ${GlobalData.check_login_time}`, logCallback);
                        if((timestamp - modifytTimestamp) > GlobalData.check_login_time){ 
                          var signOut = function (){                
                            GlobalData.uid = "";
                            GlobalData.user = {};
                            router.push(
                            {
                                name : 'Empty',
                                params:{
                                    isLogOut: 'Y',
                                }
                            });
                          } 
                          JsBridge.callHandler('signVueOut', "", signOut);      
                        }else{
                          checkTaskFirst();
                        }
                      }else{
                        checkTaskFirst();
                      }
                    }else{
                      checkTaskFirst();
                    }
                  }
              )
              .catch((err)=> {
                  oltConsole.log(err);
              });
            }else{
              checkTaskFirst();
            }
          }else{
            checkTaskFirst();
          }
        }else{
          checkTaskFirst();
        }
      };
      
      window["getUserDataCallback"] = getUserDataCallback;  
      JsBridge.callHandler('getUserData', "", getUserDataCallback);
    }else{
      GlobalData.isOffline = true;
    } 
  };

  window["getNetworkCallback"] = getNetworkCallback;  
  if(!JsBridge.isBrowser()){
    JsBridge.callHandler('getNetwork', JSON.stringify({}), getNetworkCallback);
  }else{
    next();
  }
});

router.afterEach((to, from, next) => {  
});

export default router
