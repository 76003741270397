// 物件 ES6 封裝
// ES6 必需 EXPORT 出物件才能被 import 使用
import { oltConsole } from "@/lib/oltModule/oltConsole"
import { restApi } from "@/include/config";
import axios from 'axios';
// init oltConsole
export class oltRestApiClient{


	constructor () {
		//const oltConsole = new oce() ; 
		//oltConsole.log("init oltRestApiClient Object !!!");
		//this.path
		this.params = {} ; 
		this.jwtAuthHeader = {} ; 
		if(oltConsole.oltExpire === true){
			oltConsole.sysExpireAlert();
		}

	}

	initJwtAuthHeader(){
		const item = localStorage.getItem('user');
		if(item && typeof item === 'object'){
			this.user = JSON.parse(localStorage.getItem('user'));
			if(this.user){
				this.jwtAuthHeader = {
					'Authorization': 'Bearer '+this.user.token.original.token,
				};
			}
		}
	}

	// conditon method start 
	conditionParam(param){

		
		//oltConsole.setDebugMode(true);
		//oltConsole.log("conditionParam test :");
		//oltConsole.log(param);

		if(param.apiName){
			this.params = {
				"fun":param.fun ,
				"act":param.act ,
				"ctype":param.conditionType , 
				"pky":param.pky , 
				"paginate":param.pagination ,
				"page":param.currentPage ,
				"sort":param.currentSort ,
				"sortby":param.currentSortOrder ,
				"groupby":param.currentGroupBy ,
				"filter":param.filterQuery ,
				"search":param.searchQuery ,
			};
		}
		// if ctype is null 
		if(!this.params.ctype){
			this.params.ctype = "pky";
		}
	}
	conditionRestApiUrl(param,item){

		//oltConsole.log("conditionRestApiUrl() : ");
		this.apiIdTable = "";
		if(this.params.ctype == "pky" && param.conditionType){
			this.matchKey = []; 
			//oltConsole.log("custom api get url : ");
			for (const [pky, table] of Object.entries(this.params.pky)) {
				//oltConsole.log(`${pky}: ${table}`);
				for (const [key, value] of Object.entries(item)) {
					if(pky == key && value && table){
						this.matchKey.push(key) ; 
						this.apiIdTable += `/${table}/${value}`;
					}
				}
			}
			//oltConsole.log("matchKey : ");
			//oltConsole.log(this.matchKey);
		}
		else{
			//oltConsole.log("conditionType is null !");
		}
		if(this.apiIdTable != ""){
			this.restApiUrl = restApi.pathRoot+this.apiIdTable ; 
		}
		else{
			// 假如沒有指定。pky 的內容 Table name 預設抓 apiName , PKY 預設用 id  
			this.idValue = "";
                        for (const [key, value] of Object.entries(item)) {
                                if(key == "id"){
					//oltConsole.log("Key Value : "+value);
					this.idValue = value ; 
                                }
                        }

			
			this.restApiUrl = restApi.pathRoot+"/"+param.apiName+"/"+this.idValue ; 
			//console.log(this.restApiUrl);
		}
	}

	conditionDebug(param,item,message,debug){

		if(param){
			oltConsole.setDebugMode(param.apiDebug);
			oltConsole.setDebugMode(debug);
			oltConsole.log(message);
			oltConsole.log("./-> Config restApi : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> function->params : ");
			oltConsole.log(param);
			oltConsole.log("./-> this.params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> selectItems : ");
			oltConsole.log(item);
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
			if(this.params.pky){
				var mkobj ; 
				for (let mk of this.matchKey) {
					for (const [pk, pv] of Object.entries(this.params.pky)) {
						if(mk != pk){
							//oltConsole.log("== mk : "+ mk +" , pk : "+pk + " , pv : " + pv);
							oltConsole.log("./../-> Warning pky setup error : ");
							oltConsole.log("./../-> PKY {"+ pk + "} : 沒有對應到 選定的 Item 的值，您的 API URL 的路徑將會有所缺失 請參考 this.apiIdTable 的路徑");
						}
						else{
							//oltConsole.log("!= mk : "+ mk +" , pk : "+pk + " , pv : " + pv);
						}
					}
				}
			}
			if(this.apiIdTable){
				oltConsole.log("./-> this.apiIdTable : " + this.apiIdTable);
			}
			if(this.idValue){
				oltConsole.log("./-> this.idValue : " + this.idValue);
			}
			oltConsole.log("./../-> restApiUrl : " + this.restApiUrl );
		}
	}


	// conditon method end 


	// 讀取全部資源 
	getAll(param){
		if(param){


			if(param.act == "" || !param.act){
				param.act = "view";
			}

			//oltConsole.setDebugMode(param.apiDebug);
			oltConsole.setDebugMode(param.apiGetPageDebug);
			oltConsole.log("[API]oltRestApiClient getAll() : ");
			oltConsole.log("[API]oltRestApiClient apiGetPageDebug : ");
			if(param.apiGetRtDebug){
				oltConsole.setDebugMode(param.apiGetRtDebug);
				oltConsole.log("[API]oltRestApiClient apiGetRtDebug() call getAll() : ");
			}
			//oltConsole.setDebugMode(true);
			oltConsole.log("./-> config : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> params : ");
			oltConsole.log(param);

		}
		if(param.apiName){

			// Ethon 2021/05/21 Add Start
			// 新增 符合 Client 端串接習慣的 參數 設定
			if(param.apiPlatform == 'client'){
				this.params = param ; 
			}
			// Ethon 2021/05/21 Add End 
			else{
				this.params = {
					"fun":param.fun ,
					"act":param.act ,
					"pageMode":param.pageMode ,
					"paginate":param.pagination ,
					"page":param.currentPage ,
					"sort":param.currentSort ,
					"sortby":param.currentSortOrder ,
					"groupby":param.currentGroupBy ,
					"filter":param.filterQuery ,
					"search":param.searchQuery ,
					"rtCondition":param.rtCondition ,
				};
			}

		}
		this.initJwtAuthHeader();

		this.restApiUrl = restApi.pathRoot+'/'+param.apiName ; 
		if(param){
			//oltConsole.setDebugMode(true);
			oltConsole.log("./-> this.restApiUrl : ");
			oltConsole.log(this.restApiUrl);
			oltConsole.log("./-> this.params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
		}
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'get' , 
					url: this.restApiUrl,
					params:	this.params , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
				// 失敗
				//oltConsole.log("Error : ");
				//oltConsole.log(err);
				oltConsole.log("./-> err : ") ;
				oltConsole.log(err);
				oltConsole.log(err.response) ;
				reject(err)
			})
		}) ; 
	}


	getAllRecord(param, item){
		if(param){
			oltConsole.setDebugMode(param.apiDebug);
			oltConsole.log("[API]oltRestApiClient getAllRecord() : ");
			oltConsole.log("./-> config : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> params : ");
			oltConsole.log(param);
			oltConsole.log("./-> items : ");
			oltConsole.log(item);
		}
		if(param.apiName){
			this.params = {
				"fun":param.fun ,
				"act":param.act ,
				"pageMode":param.pageMode ,
				"paginate":param.pagination ,
				"page":param.currentPage ,
				"sort":param.currentSort ,
				"sortby":param.currentSortOrder ,
				"filter":param.filterQuery ,
				"search":param.searchQuery 
			};
		}
		if(item){
			for(var key in item){  
				this.params[key] = item[key];
			}  
		}
		this.initJwtAuthHeader();
		this.restApiUrl = restApi.pathRoot+'/'+param.apiName ; 
		if(param){
			oltConsole.log("./-> this.restApiUrl : ");
			oltConsole.log(this.restApiUrl);
			oltConsole.log("./-> this.params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
		}
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'get' , 
					url: this.restApiUrl,
					params:	this.params , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
				// 失敗
				//oltConsole.log("Error : ");
				//oltConsole.log(err);
				oltConsole.log("./-> err : ") ;
				oltConsole.log(err);
				oltConsole.log(err.response) ;
				reject(err)
			})
		}) ; 
	}




	getRtAll(apiName,rtCondition,oAAparam){
                let param = {} ;
                param.apiDebug = oAAparam.apiDebug ; 
                param.apiGetRtDebug = oAAparam.apiGetRtDebug ; 
                param.apiName = apiName ; // 要取得的 API name 
                param.pageMode = false ;  // 關閉換頁模式
                param.rtCondition = rtCondition ;  
		param.fun = oAAparam.fun;
		param.act ='rt';
		oltConsole.setDebugMode(oAAparam.apiDebug);
		oltConsole.log("[API]oltRestApiClient getRtAll() : ");
		oltConsole.setDebugMode(oAAparam.apiGetRtDebug);
		oltConsole.log("[API]oltRestApiClient apiGetRtDebug : ");
		oltConsole.log(param);
		let resObj = this.getAll(param);
                return new Promise((resolve, reject) =>{
                        resObj
                        .then((res)=> {
				resolve(res);
				//let rtRecord = {} ; 
				//rtRecord.[apiName] = res.[apiName].data  ;
                        	//resolve(rtRecord);
                        })
                        .catch((err)=> {
			    oltConsole.log(err.response) ;
                            reject(err)
                        })
                }) ; 
	}


	// 判斷  PKY 自動提取 資料庫 該 PKY 的內容～
	// 若未指定 PKY 預設 漠認 PKY 為 id 
	getCondition(param,item){
		this.conditionParam(param); // init this.params 
		this.conditionRestApiUrl(param,item); 
		this.initJwtAuthHeader() ; 
		let debug = param.apiGetOneDebug ; 
		this.conditionDebug(param,item,"[API]oltRestApiClient getCondition() : ",debug);

		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'get' , 
					url: this.restApiUrl,
					params:	this.params , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
				// 失敗
				//oltConsole.log("Error : ");
				//oltConsole.log(err);
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);				
			 	oltConsole.log(err.response) ;
				reject(err)
			})
		}) ; 
	}




	// 新增資源，也作為萬用動詞處理其它要求。
	post(param){
		this.initJwtAuthHeader() ; 
		if(param){
			oltConsole.setDebugMode(param.apiDebug);
			oltConsole.log("[API] oltRestApiClient post() : ");
			oltConsole.setDebugMode(param.apiPostDebug);
			oltConsole.log("[API]oltRestApiClient apiPostDebug : ");
			oltConsole.log("./-> Config : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> Interface params : ");
			oltConsole.log(param);
		}
		let form = param.form ; 
		let fun = param.fun ;
		let act = param.act ; 
		if(param.apiName){
			this.params = form;
			if(this.params && this.params != undefined){
				this.params.fun = fun;
				this.params.act = act;
			}
		}
		const item = localStorage.getItem('user');
		if(item && typeof item === 'object'){
			this.user = JSON.parse(localStorage.getItem('user'));
		}

		if(param){
			oltConsole.log("./-> Api params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> restApi Url : ") ;
			oltConsole.log(restApi.pathRoot+'/'+param.apiName) ;
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
		}
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'post' , 
					url: restApi.pathRoot+'/'+param.apiName,
					data:	this.params , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);				
				reject(err)


			})
		}) ; 
	}

	postFile(param, file){
		this.initJwtAuthHeader() ; 
		if(param){
			oltConsole.setDebugMode(param.apiDebug);
			oltConsole.log("[API] oltRestApiClient post() : ");
			oltConsole.setDebugMode(param.apiPostDebug);
			oltConsole.log("[API]oltRestApiClient apiPostDebug : ");
			oltConsole.log("./-> Config : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> Interface params : ");
			oltConsole.log(param);
		}
		let form = param.form ; 
		if(param.apiName){
			this.params = form;
		}

		var formData = new FormData();

		if(param){
			oltConsole.log("./-> Api params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> restApi Url : ") ;
			oltConsole.log(restApi.pathRoot+'/'+param.apiName) ;
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
			Object.keys(param).forEach(function(k){
				if(param[k] != null && param[k] != undefined){
					formData.append(k, param[k]);
				}
			});
		}
		if(file){
			formData.append('file', file);		
			this.jwtAuthHeader['Content-Type'] = 'multipart/form-data';	
		}
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'post' , 
					url: restApi.pathRoot+'/'+param.apiName,
					data: formData , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);				
				reject(err)


			})
		}) ; 
	}
	
	
	
	postFile3(param, file, file2, file3){
		this.initJwtAuthHeader() ; 
		if(param){
			oltConsole.setDebugMode(param.apiDebug);
			oltConsole.log("[API] oltRestApiClient post() : ");
			oltConsole.setDebugMode(param.apiPostDebug);
			oltConsole.log("[API]oltRestApiClient apiPostDebug : ");
			oltConsole.log("./-> Config : ");
			oltConsole.log(restApi);
			oltConsole.log("./-> Interface params : ");
			oltConsole.log(param);
		}
		let form = param.form ; 
		if(param.apiName){
			this.params = form;
		}

		var formData = new FormData();

		if(param){
			oltConsole.log("./-> Api params : ");
			oltConsole.log(this.params);
			oltConsole.log("./-> restApi Url : ") ;
			oltConsole.log(restApi.pathRoot+'/'+param.apiName) ;
			oltConsole.log("./-> Token Key : ") ;
			if(this.user){
				oltConsole.log(this.user.token.original.token) ;
			}
			Object.keys(param).forEach(function(k){
				if(param[k] != null && param[k] != undefined){
					formData.append(k, param[k]);
				}
			});
		}
		if(file){
			formData.append('file', file);		
			this.jwtAuthHeader['Content-Type'] = 'multipart/form-data';	
		}
		
		if(file2){
			formData.append('file2', file2);		
			this.jwtAuthHeader['Content-Type'] = 'multipart/form-data';	
		}
		
		if(file3){
			formData.append('file3', file3);		
			this.jwtAuthHeader['Content-Type'] = 'multipart/form-data';	
		}
		
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'post' , 
					url: restApi.pathRoot+'/'+param.apiName,
					data: formData , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);				
				reject(err)


			})
		}) ; 
	}
	// 替換資源，替換單一或集合資源。 
	// 不管三七二十一內容全部更新 
	put(param,item){


		this.conditionParam(param);
		this.conditionRestApiUrl(param,item); 
		this.initJwtAuthHeader() ; 
		let debug = param.apiPutDebug ; 
		this.conditionDebug(param,item,"[API] oltRestApiClient put() : ",debug);
		let formParams = {} ; 
		formParams = param.form ; 
		formParams.fun = param.fun ; 
		formParams.act = param.act ; 
		formParams.selectItems = item ; 
		//console.log("param : ");
		//console.log(param);
		//console.log("formParams : ");
		//console.log(formParams);
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'put' , 
					url: this.restApiUrl,
					params:	formParams , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				// 成功 
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
				// 失敗
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);				
			 	oltConsole.log(err.response) ;
				reject(err)
			})
		}) ; 

	}
	
	// 更新資源部份內容
	// 只修改部份的內容
	patch(){

	}



	// 刪除資源
	delete(param,item){
		this.conditionParam(param);
		this.conditionRestApiUrl(param,item); 
		this.initJwtAuthHeader() ; 
		let debug = param.apiDeleteDebug ; 
		this.conditionDebug(param,item,"[API]oltRestApiClient delete()",debug);
		// Promise 進行異步 IO 封裝並且回傳。
		return new Promise((resolve, reject) =>{
			axios(
				{
					method:'delete' , 
					url: this.restApiUrl,
					params:	this.params , 
					headers:this.jwtAuthHeader
				}
			)
			.then(res => {
				// 成功 
				oltConsole.log("./-> res : ") ;
				oltConsole.log(res);
				resolve(res.data);
		        })
			.catch(err =>{
				// 失敗
                                oltConsole.log("./-> err : ") ;
                                oltConsole.log(err);
			 	oltConsole.log(err.response) ;
				reject(err)
			})
		}) ; 




	}
	
	// 取得資源 但是只取得 HTTP HEADER 的內容。
	head(){

	}

	// 錯誤處理 
	error(){

	}


}
oltConsole.sysExpireTime();
// resouces : https://tw.twincl.com/programming/*641y 
/*
	PUT通常是用來替換單一資源或資源集合 (resource collection) 的內容。

	POST除了用來新增資源，也作為catch-all用途，例如用在utility API。（Utility API是不同於一般資源讀寫操作的要求類型，例如檢查某個促銷活動碼是否有效。）

	PATCH用來更新資源部份內容。前幾年有人會用POST代替PATCH，現在應該沒這必要了；建議除非infrastructure有限制，否則直接用PATCH即可。
*/

/*
2xx: 成功
200 OK: 通用狀態碼
201 Created: 資源新增成功
202 Accepted: 請求已接受，但尚在處理中
204 No Content: 請求成功，但未回傳任何內容
3xx: 重新導向
301 Moved Permanently: 資源已移至它處
303 See Other: 回傳的內容可在它處取得（例如在用戶端發送了一個POST請求之後）
304 Not Modified: 請求的資源並未修改（通常是用戶端發送了帶有If-Modified-Since或If-None-Match表頭的請求）
4xx: 用戶端錯誤（用戶端不應retry原始請求）
400 Bad Request: 通用狀態碼
401 Unauthorized: 用戶端尚未驗證*
403 Forbidden: 用戶端被禁止此請求*
404 Not Found: 請求的資源不存在
405 Method Not Allowed: 不支援請求的HTTP方法
406 Not Acceptable: 不支援請求所要求的內容類型*（Accept表頭）
415 Unsupported Media Type: 不支援請求所用的內容類型*（Content-Type表頭）
5xx: 伺服器錯誤（用戶端可合理retry）
500 Internal Server Error: 工程師要找bug了
501 Not Implemented: 用戶端的請求目前未支援（也就是將來有可能支援）
502 Bad Gateway: 上游的伺服器未回傳正確結果，一般是gateway或proxy server才會回傳此狀態碼
503 Service Unavailable: 暫停服務（也就是過不久就會恢復服務──如果一切順利的話）
504 Gateway Timeout: 上游的伺服器逾時，一般是gateway或proxy server才會回傳此狀態碼 
*/
