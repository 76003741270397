
export default {
    uid: "",
    token: "",
    user: {},
    isOffline: false,
    contact_service_add_contact_time: 0,
    back_url: "",
    user_level_level1: "level1",
    user_level_level2: "level2",
    user_level_level3: "level3",
    user_level_level4: "level4",
    login_redirect_type_home_video: "home-video",
    login_redirect_type_home_listen: "home-listen",
    isDebug: true,
    check_login_status: 'Y',
    check_login_time: 1800,
    // 每日學習，不在彈出視窗
    isRepeat: "Y",
    //temp
    //畫面切換時的所需佔存資料
    temp_everybody_level_elid:'',
    // 最後一個開啟的lsid
    lastLsid: "",
}
