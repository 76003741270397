// 物件 ES6 封裝
// ES6 必需 EXPORT 出物件才能被 import 使用
import { restApi } from "@/include/config";
import { oltConsole } from "@/lib/oltModule/oltConsole"
import { oltApiAccess } from "@/lib/oltModule/oltApiAccess"
import { oltRestApiClient } from "@/lib/oltModule/oltRestApiClient"

export class oltApiClient{

	constructor () {
		this.oAA = new oltApiAccess() ; 
	}

	getApiParam(obj){
		return this.oAA.getApiParam(obj);
	}

	getClientApiParam(obj){
		return this.oAA.getClientApiParam(obj);
	}


	paserFormToForm(obj){
		return this.oAA.paserFormToForm(obj);
	}

	apiParamToField(){

	}




	searchQueryValueMarge(obj){
		return this.oAA.searchQueryValueMarge(obj); 
	}

	queueGetPageRecord(param,queueSpeed) {
		return this.oAA.queueGetPageRecord(param,queueSpeed); 
	}

	queueGetAllRtRecord(apiName,queueSpeed){
		return this.oAA.queueGetAllRtRecord(apiName,queueSpeed); 
	}

	queueRtSetComparisonPageRtRecord(rtSet,recordData){
		return this.oAA.queueRtSetComparisonPageRtRecord(rtSet,recordData); 
	}

	queueGetPageRtRecord(rk,rtCondition , queueSpeed){
		return this.oAA.queueGetPageRtRecord(rk,rtCondition , queueSpeed);
	}

	async queueGetAll(obj,param,cb) {
		return this.oAA.queueGetAll(obj,param,cb);
	}

	getOneRecord(item,param){
		return this.oAA.getOneRecord(item,param);
	}

	getAllRecord(param, item){
		let orac = new oltRestApiClient();   
		let resObj = orac.getAllRecord(param,item) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
    			oltConsole.setDebugMode(true);
				oltConsole.log("getAllRecord : ");
				oltConsole.log(res);
				resolve(res);
			})
			.catch((err)=> {
			    reject(err) ;
			})
		}) ; 
	}

	postFileRecord(param, file){
		let orac = new oltRestApiClient();   
		let resObj = orac.postFile(param, file) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
				resolve(res);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}
	postFileRecord3(param, file, file2, file3){
		let orac = new oltRestApiClient();   
		let resObj = orac.postFile3(param, file,file2,file3) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
				resolve(res);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}

	postRecord(param){
		return this.oAA.postRecord(param);
	}

	putRecord(param,item){
		return this.oAA.putRecord(param,item);
	}

	deleteRecord(item,param){
		return this.oAA.deleteRecord(item,param);
	}

	confirmDelete(item){
		return this.oAA.confirmDelete(item);
	}

	doubleConfirmDelete(res,item){
		return this.oAA.doubleConfirmDelete(res,item);
	}
}
