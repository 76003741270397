// 框架封裝取用並初始化 EXPORT 給 VUE 
import { config } from "@/include/config";
// 這個封裝要使用時才拿 
//import { dataTable } from "@/lib/dataTable";
import { auth } from "@/lib/oltModule/auth"
import { oltConsole } from "@/lib/oltModule/oltConsole"
import { oltRestApiClient } from "@/lib/oltModule/oltRestApiClient"
import { oltApiAccess } from "@/lib/oltModule/oltApiAccess"
import { oltApiClient } from "@/lib/oltModule/oltApiClient"
//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap';

const oltObj = {
  config,
};

let oAA = new oltApiAccess ; 
let oAC = new oltApiClient ; 

export { oltObj , config  , oltConsole , oltRestApiClient , oAA, oAC , auth };
