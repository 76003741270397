import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import JsBridge from './lib/JsBridge'
import GlobalData from './data/GlobalData'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
const hls = require('videojs-contrib-hls')
Vue.use(VideoPlayer)
Vue.use(hls)
// 產品開發模式 
Vue.config.productionTip = false
Vue.prototype.$GlobalData = GlobalData
Vue.prototype.$JsBridge = JsBridge


//全局註冊自定義指令，用於判斷當前圖片是否能夠加載成功，可以加載成功則賦值爲img的src屬性，否則使用默認圖片
Vue.directive('real-img', async function (el, binding) {//指令名稱爲：real-img
  let imgURL = binding.value;//獲取圖片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
          el.setAttribute('src', imgURL);
      } 
  }
})
Vue.directive('real-background-img', async function (el, binding) {//指令名稱爲：real-background-img
  let imgURL = binding.value;//獲取圖片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
          el.setAttribute('style', "background-image: url('"+imgURL+"')");
      } 
  }
})
Vue.directive('real-extend-new-knowledge-background-img', async function (el, binding) {//指令名稱爲：real-extend-new-knowledge-background-img
  let imgURL = binding.value;//獲取圖片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
          el.setAttribute('style', "background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4)58%, rgba(0, 0, 0, 0.1)), url('"+imgURL+"')");
      } 
  }
})
Vue.directive('real-task-list-background-img', async function (el, binding) {//指令名稱爲：real-background-img
  let imgURL = binding.value;//獲取圖片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
          el.setAttribute('style', "background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url('"+imgURL+"');	background-position: center;background-size: 100%, 100%;background-repeat: no-repeat; background-size: cover;");
      } 
  }
})

// 等級測驗選項圖片
Vue.directive('real-exam-background-img', async function (el, binding) { //指令名稱爲：real-exam-background-img
  let imgURL = binding.value;//獲取圖片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('style', "background-image: url('"+imgURL+"'); background-size: cover;");
    } 
  }
})


/**
* 檢測圖片是否存在
* @param url
*/
let imageIsExist = function(url) {
  return new Promise((resolve) => {
      var img = new Image();
      img.onload = function () {
          if (this.complete == true){
              resolve(true);
              img = null;
          }
      }
      img.onerror = function () {
          resolve(false);
          img = null;
      }
      img.src = url;
  })
}

// 初始化


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


// 檢查是否轉跳畫面
function checkBackCallback(backUrl){
  if(backUrl && backUrl != ""){
    var name = "";
    var params = {};
    if(backUrl.indexOf("?") > -1){
        name = backUrl.substring(0, backUrl.indexOf("?"));
        var queryStr = backUrl.substring(backUrl.indexOf("?") + 1, backUrl.length);
        if(queryStr != ""){
        const urlSearchParams = new URLSearchParams(queryStr);   
            params = Object.fromEntries(urlSearchParams.entries()); 
        }
        if(params != null && params != undefined && params.backUrl != undefined && params.backUrl != ""){
          var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
          if(base64.test(params.backUrl)){
            params.backUrl = atob(params.backUrl);
          }
        }
    }else{
      name = backUrl;
    }
    router.push(
    {
        name : name,
        params : params
    });
  }
}

if(!JsBridge.isBrowser()){
  JsBridge.callHandler('checkBack', "", checkBackCallback);
}

window["updateView"] = updateView;
function updateView(name){
  var index = name.indexOf("?");
  var query = {};
  if(index != -1){
    var searc = name.substring(index, name.length);        
    const urlSearchParams = new URLSearchParams(searc);
    query = Object.fromEntries(urlSearchParams.entries()); 
    name = name.substring(0, index);
  }
  router.push({
    name: name,
    query: query,
  });
}

