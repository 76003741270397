// 物件 ES6 封裝
// ES6 必需 EXPORT 出物件才能被 import 使用
import { restApi } from "@/include/config";
import { oltConsole } from "@/lib/oltModule/oltConsole"
import { oltRestApiClient } from "@/lib/oltModule/oltRestApiClient"
import swal from "sweetalert2";

// init oltConsole
export class oltApiAccess{

	getApiParam(obj){
	    if(obj.platform == 'client'){
		let tft = {} ; 
	        tft = this.paserFormToForm(obj);
		//console.log("test form : ");
		//console.log(tft);
		obj.form = tft ; 

	    }

	    obj.searchQuery = this.searchQueryValueMarge(obj) ; 
	    this.param = {
	    	"apiDebug": 		obj.apiDebug		, 
	    	"apiGetPageDebug": 	obj.apiGetPageDebug	, 
	    	"apiGetRtDebug": 	obj.apiGetRtDebug	, 
	    	"apiGetOneDebug": 	obj.apiGetOneDebug	, 
	    	"apiPostDebug": 	obj.apiPostDebug	, 
	    	"apiPutDebug": 		obj.apiPutDebug		, 
	    	"apiDeleteDebug": 	obj.apiDeleteDebug	, 
	    	"pageMode": 		obj.pageMode , 
	    	"apiName": 		obj.apiName , 
	    	"fun": 			obj.fun , 
	    	"act": 			obj.act , 
	    	"apiPlatform": 		obj.platform , 
	    	"pagination":		obj.perPage , 
	    	"currentPage":		obj.currentPage ,
	    	"currentSort":		obj.currentSort ,
	    	"currentSortOrder":	obj.currentSortOrder ,
	    	"currentGroupBy":	obj.currentGroupBy ,
	    	"conditionType":	obj.conditionType,
	    	"pky":			obj.pky,
	    	"form":			obj.form,
	    	"filterQuery":		obj.filterQuery ,
	    	"searchQuery":		obj.searchQuery ,
	    } 
	    return this.param ; 
	}

	paserFormToForm(obj){
	    //console.log("paserFormToForm :  ");
	    //console.log(obj);
	    let ftf={} ; 
            if(obj){
		for (const [sk, sv] of Object.entries(obj)){
			//this.param[sk] = sv ;
			//console.log("key : "+ sk + ", value : " + sv);
			if(sk.indexOf('form_') === 0){
				//console.log(sk.replace('form_',''));
				//console.log(sv);
				ftf[sk.replace('form_','')] = sv ; 
				//this.form[sk.replace('form_','')] = sv ; 
				//console.log(ftf);
				
			}
		}
	    }
	    //console.log(ftf);
	    return ftf;
	}


	getClientApiParam(obj){
	    this.param = {
	    	"apiDebug": 		obj.apiDebug		, 
	    	"apiGetPageDebug": 	obj.apiGetPageDebug	, 
	    	"apiGetRtDebug": 	obj.apiGetRtDebug	, 
	    	"apiGetOneDebug": 	obj.apiGetOneDebug	, 
	    	"apiPostDebug": 	obj.apiPostDebug	, 
	    	"apiPutDebug": 		obj.apiPutDebug		, 
	    	"apiDeleteDebug": 	obj.apiDeleteDebug	, 
	    	"apiName": 		obj.apiName , 
	    	"fun": 			obj.fun , 
	    	"act": 			obj.act , 
	    	"apiPlatform": 		obj.platform , 
	    } 
	    //console.log(obj.apiParam);
            if(obj.apiParam){
		for (const [sk, sv] of Object.entries(obj.apiParam)){
			this.param[sk] = sv ;
			//console.log("key : "+ sk + ", value : " + sv);
		}
	    }
	    //console.log(this.param);
	    return this.param ; 
	}

	searchQueryValueMarge(obj){
		if(obj.searchQuery){
			for (const [sk, sv] of Object.entries(obj.searchQuery)){
				//obj.searchQuery.[sk].value = obj.[sk] ;
				obj.searchQuery[sk].value = obj[sk] ;
			}
		}
		return obj.searchQuery ; 
	}

	queueGetPageRecord(param,queueSpeed) {
    		//oltConsole.setDebugMode(true);
		oltConsole.log("oAA.oltGetPageRecord : ");
		//console.log("test"); 
		// page record 
		let orac = new oltRestApiClient();   
		oltConsole.log(orac);
		oltConsole.log(param);
		var resObj = orac.getAll(param) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
              			setTimeout(() => {
				    resolve(res);
              			}, queueSpeed);
			})
			.catch((err)=> {
			    reject(err) ;
			})
		}) ; 
	}

	queueGetAllRtRecord(apiName,queueSpeed){
		//oltConsole.setDebugMode(true);
		oltConsole.log("oAA.getAllRtRecord :");
		//oltConsole.log(apiName);
		//oltConsole.log(this.param.apiDebug);
		oltConsole.log(this.param);
		let orac = new oltRestApiClient();   
		let resObj = orac.getRtAll(apiName,"",this.param) ;
		//console.log(resObj);
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
		              setTimeout(() => {
				resolve(res);
		              }, queueSpeed);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}


	queueRtSetComparisonPageRtRecord(rtSet,recordData){
    		//oltConsole.setDebugMode(true);
		//oltConsole.log("queueRtSetComparsionPageRtRecord : " );
		//oltConsole.log(rtSet);
		//oltConsole.log(recordData);
		//oltConsole.log(this.param.apiDebug);
		// 取得 data table hapi  record 值並帶入 rt 
		if(rtSet){
			for (const [rk, rv] of Object.entries(rtSet)){
				//console.log("rtSet : ");
				//console.log(rv);
				if(rv != "all"){ // 取得pageRecord 關聯的 RT 內容 
					//oltConsole.log("createPageRtRtcord "+ rk +" : ");
					if(rv["condition"] == "Y"){
						
					}
					else{
						for(const [rvk, rvv] of Object.entries(rv)){
							let vArr = [];
							for (const [rdk, rdv] of Object.entries(recordData)){
								rtSet[rk][rvk] = vArr;
								vArr.push(rdv[rvk]);
							}
						}
					}
    					//oltConsole.setDebugMode(true);
					//oltConsole.log(rtSet[rk]);
				}
			}
			return rtSet ; 
		}
		return false ; 
	}



	queueGetPageRtRecord(rk,rtCondition , queueSpeed){

		// 去除重覆
		if(rtCondition){
			for(const [rck, rcv] of Object.entries(rtCondition)){
				let rcvv = new Set(rcv);
				rcvv = Array.from(rcvv);
				rtCondition[rck] = rcvv ; 
				//oltConsole.log(rcvv);
			}
		}
		else{
			console.log( "Error : rtCondition is required!!" ) ;
		}
    		//oltConsole.setDebugMode(true);
		//oltConsole.log("oltApiAccess.queueGetPageRtRecord : ");
		//oltConsole.log(rk);
		//oltConsole.log(rtCondition);
		let orac = new oltRestApiClient();   
		let resObj = orac.getRtAll(rk,rtCondition,this.param) ;
		//console.log(resObj);
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
    				//oltConsole.setDebugMode(true);
				//oltConsole.log("oAA.getPageRtRecord 2 : ");
				//oltConsole.log(res);
		              setTimeout(() => {
				resolve(res);
		              }, queueSpeed);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}

	async queueGetAll(obj,param,cb) {
	    //oltConsole.log("queueGetAll : ");
	    // queue1 get rtAllrecord 
	    //oltConsole.log(obj.rtSet);
	    // GET rtAllRecord 
	    if(obj.rtSet){
		var rtAllRecordArr = {} ; 
		var rArr = {} ; 
		//oltConsole.log("queueSpeed : " + obj.queueSpeed);
		for (const [rk, rv] of Object.entries(obj.rtSet)){
	    	//console.log(rk);   console.log(rv);
			if(rv == "all"){ 
    				rArr = await this.queueGetAllRtRecord(rk,obj.queueSpeed) ; 
				//console.log(rArr)
				// Ethan 2021_05_21 add check .data 
				if(!rArr[rk].data){
    					rtAllRecordArr[rk] = rArr[rk] ; 
				}
				else{
    					rtAllRecordArr[rk] = rArr[rk].data ; 
				}
			}
		}
	    }
	    //console.log("rtAllRecord : ");
	    //console.log(rtAllRecordArr);
	    // queue2 get pageRecord 
            const rtAllRecord = rtAllRecordArr ;
            const pageRecord = await this.queueGetPageRecord(param,obj.queueSpeed) ; 
	    //console.log(pageRecord);
	    // queue3 get rtPageRecord 
	    if(obj.rtSet){

		let records = Object.values(pageRecord)[0].data ; 
		if(!records){
			records = Object.values(pageRecord)[0] ; 
		}
		//console.log(records);
		let rtSet = await this.queueRtSetComparisonPageRtRecord(obj.rtSet,records);
    		//oltConsole.setDebugMode(true);
		//oltConsole.log(rtSet);
		//console.log(rtSet);
		var rtPageRecordArr = {} ; 
		var rArr = {} ; 
		for (const [rk, rv] of Object.entries(rtSet)){
			if(rv !== "all"){
				rArr = await this.queueGetPageRtRecord(rk,rv,obj.queueSpeed) ; 
				
				// Ethan 2021_05_22 add check .data 
				if(rArr[rk].data){
    					rtPageRecordArr[rk] = rArr[rk].data ; 
				}
				else{
    					rtPageRecordArr[rk] = rArr[rk] ; 
				}
			}
		}

	    }
	    //console.log(rtPageRecordArr);
            const rtPageRecord = rtPageRecordArr ;
	    let allObj = {rtAllRecord,pageRecord,rtPageRecord} ; 
	    return allObj ; 
	}



	getOneRecord(item,param){


		let orac = new oltRestApiClient();
		let resObj = orac.getCondition(param,item) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
			    resolve(res);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}

	postRecord(param){
		//oltConsole.log("oAA.postRecord(param) : ");
		//console.log("oAA.postRecord(param) : ");
		//console.log(param);
		let orac = new oltRestApiClient();   
		let resObj = orac.post(param) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
    				//oltConsole.setDebugMode(true);
				//oltConsole.log("postRecord : ");
				//oltConsole.log(res);
				resolve(res);
			})
			.catch((err)=> {
			    reject(err) ; 
			})
		}) ; 
	}

	putRecord(param,item){
    		//oltConsole.setDebugMode(true);
		//oltConsole.log("oAA.putRecord() : ");
		//oltConsole.log(param);
		//oltConsole.log(item);
		//exit ; 
		let orac = new oltRestApiClient();   
		let resObj = orac.put(param,item) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
    				//oltConsole.setDebugMode(true);
				//oltConsole.log("postRecord : ");
				//oltConsole.log(res);
				resolve(res);
			})
			.catch((err)=> {
			    reject(err) ;
			})
		}) ; 
	}

	deleteRecord(item,param){
		let orac = new oltRestApiClient();
		let resObj = orac.delete(param,item) ;
		return new Promise((resolve, reject) =>{
			resObj
			.then((res)=> {
			    resolve(res);
			})
			.catch((err)=> {
			    reject(err) ;
			})
		});
	}

	confirmDelete(item){
		return new Promise((resolve, reject) =>{
                        swal.fire({
                          title: "您確定嗎?",
                          text: `刪除的資料將無法回復。`,
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "md-button md-warning btn-fill",
                          cancelButtonClass: "md-button md-success btn-fill",
                          confirmButtonText: "是的我要刪除它",
                          cancelButtonText: "取消",
                          buttonsStyling: false
                        }).then(res => {
                      		//oltConsole.setDebugMode(true);
				//oltConsole.log("Yes i wnat delte it!!!!");
				resolve(res);
                        })
			.catch((err)=> {
			    reject(err) ;
			})
                });
	}

	doubleConfirmDelete(res,item){
                  //oltConsole.log("test doubleConfirmDelete : ");
		  //oltConsole.log(res);
		  //oltConsole.log(item);
                  if (res.value) {
			return new Promise((resolve, reject) =>{
                  		swal.fire({
                                        //icon: "success",
                                        text: `刪除 "${item.name}"`,
                                        type: "success",
                                        confirmButtonClass: "md-button md-success btn-fill",
                                        confirmButtonText: "確認刪除",
                                        buttonsStyling: false
                		})
				.then( res => {
                  	               if (res.value) {
			    			resolve(res);
                  	 	       }
                  		})
				.catch((err)=> {
				    reject(err) ;
				})
                  	});
                  }
	}



}

