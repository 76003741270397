(function(window) {

    function EasyJSBridge() {

    }
    var log = function(text) {
        console.log("EasyJSBridge: " + text);
    };

    var injectObj = function() {

        var _this = this;

        if (arguments.length < 2) {
            return;
        }

        var isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
        var isiOS = !!navigator.userAgent.match(/Mac OS X/);

        var androidObjName = "";
        var iOSObjName = "";


        var find = function(arr, e) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i] === e) {
                    return true;
                }
            }
            return false;
        };



        var getRealArags = function(args) {

            var android = [];
            var ios = [];
            var callBack = null;
            for (var i = 0; i < args.length; i++) {

                if (args[i] instanceof Array) {
                    ios = args[i];
                } else {
                    if (args[i] instanceof Function) {
                        callBack = args[i];
                    } else {
                        android.push(args[i]);
                    }
                }

            }
            var ret = {
                callBack: callBack,
                args: args
            };

            if (ios.length == 0) {
                ret.args = android;
                return ret;
            }

            if (isAndroid) {
                ret.args = android;
            } else
            if (isiOS) {
                ret.args = ios;
            }
            return ret;
        };

        var callBack = function(m) {
            return function() {

                var args = Array.prototype.slice.apply(arguments);

                if (isAndroid) {
                    if (typeof window[androidObjName] != 'undefined') {

                        if (typeof window[androidObjName][m] != 'undefined') {
                            var arg = getRealArags(args);
                            if (arg.callBack != null) {
                                EasyJSBridge[m] = arg.callBack;
                            }

                            window[androidObjName][m].apply(window[androidObjName], arg.args);
                        } else {
                            log("android webview do not define method " + m);
                        }
                    } else {
                        log("android webview do not define obj " + androidObjName);
                    }

                    return;
                }
                if (isiOS) {

                    if (iOSObjName != "") {
                        if (typeof window.webkit != 'undefined' && typeof window.webkit.messageHandlers[iOSObjName] != 'undefined') {
                            var o1 = window.webkit.messageHandlers[iOSObjName];
                            var arg = getRealArags(args);
                            if (arg.callBack != null) {
                                EasyJSBridge[m] = arg.callBack;
                            }
                            o1.postMessage({ method: m, parameter: arg.args });
                        } else {
                            log("ios webview do not define object " + iOSObjName);
                        }

                    } else {
                        if (typeof window.webkit != 'undefined' && typeof window.webkit.messageHandlers[m] != 'undefined') {
                            var o2 = window.webkit.messageHandlers[m];
                            var arg = getRealArags(args);
                            if (arg.callBack != null) {
                                EasyJSBridge[m] = arg.callBack;
                            }
                            o2.postMessage.apply(o2, arg.args);
                        } else {
                            log("ios webview do not define object " + m);
                        }
                    }
                    return;
                }

                log("call in moblie webview");
            };
        };



        var addMethods = function(ms) {

            for (var i = 0; i < ms.length; i++) {
                _this[ms[i]] = callBack(ms[i]);
            }
            if (isAndroid && typeof window[androidObjName] != 'undefined') {

                var find = function(arr, e) {
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i] === e) {
                            return true;
                        }
                    }
                    return false;
                };

                for (var j in window[androidObjName]) {
                    if (!find(ms, j)) {
                        _this[j] = callBack(j);
                    }
                }
            }
        };
        if (arguments.length === 2) {

            androidObjName = arguments[0];
            if (arguments[1] instanceof Array) {
                addMethods(arguments[1]);
                return;
            }

        } else if (arguments.length === 3) {
            androidObjName = arguments[0];
            iOSObjName = arguments[1];
            if (arguments[2] instanceof Array) {
                addMethods(arguments[2]);
                return;
            }
        }

        log("parameters error!");

    };
    EasyJSBridge.create = function() {
        var easy = new EasyJSBridge();
        injectObj.apply(easy, arguments);
        return easy;
    };
    window.EasyJSBridge = EasyJSBridge;
})(window);

export default {
    registerMethod(method){
        var methodName = method.name.replace("bound ", "");
        window[methodName] = method;
    },
    callHandler(name, data, callback) {
        var callBackName = callback.name.replace("bound ", "");
        var easyJSBridge = EasyJSBridge.create("android", "ios", [name]);
        if(easyJSBridge != null){
            window[callBackName] = callback;
            switch(name){
                case "log" :
                    easyJSBridge.log(data);
                    break;
                case "getNetwork" :
                    easyJSBridge.getNetwork(data, callBackName);
                    break;
                case "openWebView" :
                    easyJSBridge.openWebView(data, callBackName);
                    break;
                case "setUserData" :
                    easyJSBridge.setUserData(data, callBackName);
                    break;
                case "getUserData" :
                    easyJSBridge.getUserData(data, callBackName);
                    break;
                case "openVueLoginView" :
                    easyJSBridge.openVueLoginView(data, callBackName);
                    break;  
                case "openWebLoginView" :
                    easyJSBridge.openWebLoginView(data, callBackName);
                    break;                      
                case "checkBack" :
                    easyJSBridge.checkBack(data, callBackName);
                    break;         
                case "openShare" :
                    easyJSBridge.openShare(data, callBackName); 
                    break;      
                case "openVueWeb" :
                    easyJSBridge.openVueWeb(data, callBackName); 
                    break;   
                case "openInformation" :
                    easyJSBridge.openInformation(data, callBackName); 
                    break;
                case "openAlbumVue" :
                    easyJSBridge.openAlbumVue(data, callBackName); 
                    break;
                case "iabPurchaseVue" :
                    easyJSBridge.iabPurchaseVue(data, callBackName); 
                    break;
                case "iabSubscribeVue" :
                    easyJSBridge.iabSubscribeVue(data, callBackName); 
                    break;      
                case "signVueOut" :
                    easyJSBridge.signVueOut(data, callBackName); 
                    break;      
                case "getVueNativeAppVersion" :
                    easyJSBridge.getVueNativeAppVersion(data, callBackName); 
                    break;
                case "selectYearVue" :
                    easyJSBridge.selectYearVue(data, callBackName); 
                    break; 
                case "openWebVue" :
                    easyJSBridge.openWebVue(data, callBackName); 
                    break;
                case "loadCorrectAudioVue" :
                    easyJSBridge.loadCorrectAudioVue(data, callBackName); 
                    break;
                case "loadErrorAudioVue" :
                    easyJSBridge.loadErrorAudioVue(data, callBackName); 
                    break;
                case "resetMediaPlayerVue" :
                    easyJSBridge.resetMediaPlayerVue(data, callBackName); 
                    break;
                case "saveLocalDataVue" :
                    easyJSBridge.saveLocalDataVue(data, callBackName); 
                    break;
                case "loadLocalDataVue" :
                    easyJSBridge.loadLocalDataVue(data, callBackName); 
                    break;
                case "messageAlertVue" :
                    easyJSBridge.messageAlertVue(data, callBackName); 
                    break;
                case "nativeStopVoice" :
                    easyJSBridge.nativeStopVoice(data, callBackName); 
                    break;
                case "nativePlayVoiceUrl" :
                    easyJSBridge.nativePlayVoiceUrl(data, callBackName); 
                    break;
                case "bottomAlertVue" :
                    easyJSBridge.bottomAlertVue(data, callBackName); 
                    break;
                case "downloadPackage" :
                    easyJSBridge.downloadPackage(data); 
                    break;
                case "openVideoLesson" :
                    easyJSBridge.openVideoLesson(data, callBackName); 
                    break;
                case "openTeacherExplain" :
                    easyJSBridge.openTeacherExplain(data, callBackName); 
                    break;
                case "openStore" :
                    easyJSBridge.openStore(data, callBackName); 
                    break;
                case "openDeepLearnDetail" :
                    easyJSBridge.openDeepLearnDetail(data, callBackName); 
                    break;
                case "openAllExplanationWord" :
                    easyJSBridge.openAllExplanationWord(data, callBackName); 
                    break;
                case "openAllExplanationSentence" :
                    easyJSBridge.openAllExplanationSentence(data, callBackName); 
                    break;
                case "openEssayRead" :
                    easyJSBridge.openEssayRead(data, callBackName); 
                    break;
                case "setPauseVueAudio" :
                    easyJSBridge.setPauseVueAudio(data, callBackName); 
                    break;
                case "textToSpeech" :
                    easyJSBridge.textToSpeech(data, callBackName); 
                    break;
                case "openOfflineListen" :
                    easyJSBridge.openOfflineListen(data, callBackName); 
                    break;
                case "viewHeight" :
                    easyJSBridge.viewHeight(data, callBackName); 
                    break;
                case "checkPackageCount" :
                    easyJSBridge.checkPackageCount(data, callBackName); 
                    break;
                case "checkFileIsExist" :
                    easyJSBridge.checkFileIsExist(data, callBackName); 
                    break;
                case "checkNetwork" :
                    easyJSBridge.checkNetwork(data, callBackName); 
                    break;
                case "setOnBack" :
                    easyJSBridge.setOnBack(data, callBackName); 
                    break;
                    
            }
        }
    },
    isBrowser(){     
        var userAgent = window.navigator.userAgent.toLowerCase();
        if(userAgent.includes("oceanlight webview")){
            return false;
        }else{
            return true;
        }
    },
    isAndroid(){
        return  (/(Android)/i.test(navigator.userAgent));
    },
    isiOS(){
        return (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent));
    }
};
