const path_root = "http://api.chichidigital.com.tw";
const path_api 	= "api";
const old_images = "N";
const old_exam  = "N";
const old_all_explanation = "N";
const old_essay_read = "N";
const old_offline_listen = "N";
const is_show_intro = "Y";

const dev = {
	debugMode:true ,
}

const config =  { 
	path_root,
	path_api,
	old_images,
        old_exam,
	old_all_explanation,
	old_essay_read,
	old_offline_listen,
        is_show_intro

};

const restApi = {
	pathRoot: path_root + "/" + path_api
}

export { config, dev , restApi };
